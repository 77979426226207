<template>
  <div class="right-content">
<!--    <div class="top">
      <div class="cate">
        <div class="item">全部</div>
        <div class="item active">朋友</div>
        <div class="item">活动策划师</div>
        <div class="item">平面设计师</div>
        <div class="item">三维设计师</div>
      </div>
    </div>-->
    <!--  列表  -->
    <div class="my-focus-list">
      <ul>
        <li v-for="(m,index) in data_list" :key="index">
          <div class="focus-author-info">
            <div class="tx">
              <router-link :to="'/utterer/'+m.user_id">
                <img :src="m.user.avatar" :alt="m.user.nickname">
              </router-link>
            </div>
            <div class="info">
              <div class="name">{{ m.user.nickname }}</div>
              <div class="desc">{{ m.user.residecity }}<span>｜</span>{{ m.user.profession }}</div>
              <div class="statistic">
                <div class="item"><span>创作</span><i>{{ m.article_num }}</i></div>
                <div class="item"><span>粉丝</span><i>{{ m.fans_num }}</i></div>
              </div>
              <div class="op-btns">
                <div class="item"><a href="javascript:;" @click="unfollow(m.id)">取消关注</a></div>
<!--                <div class="item"><a href="javascript:;">发私信</a></div>-->
              </div>
            </div>
          </div>
          <!-- 关注人的作品-->
          <div class="focus-work">
            <div class="item" v-for="(art,index) in m.article" :key="index">
              <router-link :to="'/detail/'+art.id">
                <img :src="art.img" :alt="art.title">
              </router-link>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- 页码-->
    <div class="pagination">
      <ul>
        <li @click="prevPage" :class="{hide:current_page === 1}">
          <a class="arrow" href="javascript:;">
            <img src="static/iconImg/icon-left-arrow.png" alt="">
          </a>
        </li>
        <li v-for="page in last_page" :key="page" @click="pagination(page)">
          <a href="javascript:;" :class="{active: current_page === page}">{{ page }}</a>
        </li>
        <li @click="nextPage" :class="{hide:current_page === last_page || last_page===0}">
          <a class="arrow" href="javascript:;">
            <img src="static/iconImg/icon-right-arrow.png" alt="">
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>

import user from "@/api/user";

export default {
  name: "focus",
  components: {},
  data() {
    return {
      keyword: '',
      per_page: 12,
      total: 0,
      current_page: 1,
      last_page: 0,
      data_list: [],
      codeUrl: '',
      cid: 0,
    }
  },
  created() {
    this.getList();
  },
  methods: {
    //取消关注
    unfollow(id){
      user.unfollow({id:id}).then(res=>{
        if(res){
          this.$message.success(res.msg);
          this.getList();
        }
      })
    },
    //分页
    pagination(page) {
      if (this.current_page == page) {
        return false;
      } else {
        this.current_page = page;
        this.getList();
      }
    },
    //上一页
    prevPage() {
      if (this.current_page <= 1) {
        return false;
      } else {
        this.current_page = this.current_page - 1;
        this.getList();
      }
    },
    //下一页
    nextPage() {
      if (this.current_page >= this.last_page) {
        return false;
      } else {
        this.current_page = this.current_page + 1;
        this.getList();
      }
    },
    //改变分类
    categoryChange(cid) {
      this.cid = cid;
      this.getList();
    },
    //调取 列表数据
    getList() {
      user.getAttentionUserList({
        keyword: this.keyword,
        cid: this.cid,
        pagesize: this.per_page,
        page: this.current_page
      }).then(res => {
        if (res) {
          this.data_list = res.data.data;
          this.per_page = res.data.per_page;
          this.total = res.data.total;
          this.current_page = res.data.current_page;
          this.last_page = res.data.last_page;
        } else {
          console.log(res);
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>